import { defineStore } from "pinia";
import type { StaticProductData } from "@/types/staticProductData";

export const useStaticProductDataStore = defineStore("staticProductData", () => {
	const { getStaticDataGeneral } = usePMApi();
	const staticProductData = ref<StaticProductData | null>(null);

	async function updateStaticProductData() {
		if (!staticProductData.value) {
			const { response } = await getStaticDataGeneral();

			staticProductData.value = response?.data as StaticProductData | null;
		}
	}

	function flatTreeOptions(options: any[], level = 1): any {
		return (
			options
				.map(option => {
					if (option.children.length <= 0) {
						return {
							...option,
							level,
						};
					} else {
						return [
							{
								...option,
								level,
							},
							...flatTreeOptions(option.children, level + 1),
						];
					}
				})
				.flat(Infinity)
				.map(option => ({ ...option, title: option.text, value: option.id })) || []
		);
	}

	return {
		staticProductData,
		flatTreeOptions,
		updateStaticProductData,
	};
});
